import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalVariable } from '../global';
import { UserApiService } from '../services/user-api.service';

@Component({
  selector: 'app-all-hold-orders',
  templateUrl: './all-hold-orders.page.html',
  styleUrls: ['./all-hold-orders.page.scss'],
})
export class AllHoldOrdersPage implements OnInit {

  constructor(private server:UserApiService,private globals:GlobalVariable, private modalCtrl:ModalController) { }
holdOrders=[];
loading=false;
  ngOnInit() {
    this.gellAllHoldOrders()
  }
gellAllHoldOrders(){
  this.loading=true;
  this.server.getHoldOrders().subscribe(res=>{
    console.log(res)
  this.loading=false;

    if(res.success){
      this.holdOrders=res.data
      this.globals.holdOrdersLength=this.holdOrders.length
    }
    else{
      this.globals.holdOrdersLength=0;

    }
  },err=>{
  this.loading=false;

  })
}
close(){
this.modalCtrl.dismiss()
}
}
