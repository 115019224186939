import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalVariable } from 'src/app/global';
import { UserApiService } from 'src/app/services/user-api.service';
import { format, parseISO, getDate, getMonth, getYear } from 'date-fns';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pickup-time',
  templateUrl: './pickup-time.page.html',
  styleUrls: ['./pickup-time.page.scss'],
})
export class PickupTimePage implements OnInit {
  value: any;
  segmentSelect = 'now';
  order_time;
  pickup_time: any;
  day: any;
  time: any;
  todayDate;
  showtime: boolean = false;
  pickupTimeSlots = [];
  date: any;
  comingBack: boolean;
  timeValue: string;
  displayDate: string;
  timeshow: any;
  dateshow: any;
  dayshow: any;
  showMe: boolean = false;
  constructor(
    private modalController: ModalController,
    public globals: GlobalVariable,
    public server: UserApiService,
    private router: Router
  ) {
    this.order_time = this.globals.order_time;
    console.log(
      'order time /type ',
      this.globals.order_time,
      this.globals.pickup_Time
    );
  }

  ngOnInit() {}
  ionViewWillEnter() {
    if (this.globals.catering_enabled) {
      this.segmentSelect = 'schedule';
      this.globals.order_time = 'schedule';
    }
    setTimeout(() => {
      this.showMe = true;
    }, 1000);
    let date11 = new Date();
    //only for display
    if (this.globals.myDate) {
      this.convertDate(this.globals.myDate);
      // this.displayDate=  new Date(
      //   this.globals.myDate.getTime() -  this.globals.myDate.getTimezoneOffset() * 60000
      // ).toISOString();
    }
    // else{
    //   this.displayDate = new Date(
    //     date11.getTime() - date11.getTimezoneOffset() * 60000
    //   ).toISOString();
    // }
    //only for display

    // this.date = new Date();
    // console.log('checking for time.........s');
    //new added to avoid past orders...........
    this.todayDate = new Date(
      date11.getTime() - date11.getTimezoneOffset() * 60000
    ).toISOString();
    if (this.globals.order_time != 'now') {
      if (!this.globals.myDate) {
        this.comingBack = false;
        let date = new Date();
        // this.date = new Date();
        console.log('checking for time.........s');
        //new added to avoid past orders...........
        this.globals.myDate = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        ).toISOString();
        // this.date=this.globals.myDate

        console.log('my date', this.globals.myDate);
        this.date = format(parseISO(this.globals.myDate), 'yyyy/MM/dd');

        this.getTimeSlots();
      } else {
        this.comingBack = true;

        this.date = format(parseISO(this.globals.myDate), 'yyyy/MM/dd');
        this.getTimeSlots();
      }
    }
  }
  changeSegment(e) {
    console.log(e.detail.value);
    this.order_time = e.detail.value;
    this.globals.order_time = this.order_time;

    if (this.order_time != 'now') {
      if (!this.globals.myDate) {
        this.comingBack = false;
        let date = new Date();
        // this.date = new Date();
        console.log('checking for time.........s');
        //new added to avoid past orders...........
        this.globals.myDate = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        ).toISOString();
        console.log('my date', this.globals.myDate);
        this.date = format(parseISO(this.globals.myDate), 'yyyy/MM/dd');

        this.getTimeSlots();
      } else {
        this.comingBack = true;
        this.date = format(parseISO(this.globals.myDate), 'yyyy/MM/dd');
        this.getTimeSlots();
      }
    }
  }
  close() {
    if (this.order_time == 'now') {
      this.modalController.dismiss({
        dismissed: true,
        pickup: undefined,
      });
    } else {
      this.modalController.dismiss({
        dismissed: true,
        pickup: this.globals.myDate,
      });
    }
  }

  convertDate(date) {
    this.server.date_convert(date).subscribe(
      (data) => {
        console.log(data);

        this.dayshow = data.day;
        let parsed = Date.parse(date);
        // -newdate.getTimezoneOffset() *  60000;
        // getTime() - dateObj.getTimezoneOffset() * 60000
        // let newdate = new Date(
        //   date.getTime() - date.getTimezoneOffset() * 60000
        // );
        let time = data.time;
        time = time.split('.');
        console.log(time);
        let hours = time[0];
        let mins = time[1];
        console.log(date, hours, mins);
        this.globals.menuTime = data.date_time;

        (this.timeshow = this.tConvert(hours + ':' + mins)),
          (this.dateshow = data.date);
        // this.tConvert(data.time) for
      },
      (err) => {
        console.log(err);
      }
    );
  }
  tConvert(time) {
    console.log(time, 'convrt time');
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM ' : ' PM '; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }
  changeDate(e) {
    console.log(e.detail.value);
    // this.date = e.format('YYYY-MM-DD');
    this.date = format(parseISO(e.detail.value), 'yyyy/MM/dd');

    this.pickup_time = this.date;
    // this.pickup_time=e.detail.value;
    // this.date=this.pickup_time;
    // this.date = format(parseISO(e.detail.value), 'dd/MM/yyyy');
    // if(this.comingBack==true){
    this.getTimeSlots();

    // }
  }
  getTimeSlots() {
    if (this.date == undefined || this.date == '') {
      this.globals.presentToast('Date is not selected');
      return;
    }
    this.globals.presentToast('Please wait..', 'bottom');

    let date = new Date();
    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes();
    // let time=date.getTime()
    let ampm = hours >= 12 ? 'pm' : 'am';
    // var time: any = date.getHours() + ":" + date.getMinutes();
    let time = hours + ':' + minutes + ' ampm';
    // if(hours>12){
    let hours2;
    if (hours < 10) {
      hours2 = '0' + hours;
    } else {
      hours2 = hours;
      hours2 = hours2 % 12 || 12;
      console.log(hours2, hours2 % 12 || 12);
    }
    let mins2;
    if (minutes < 10) {
      mins2 = '0' + minutes;
      if (minutes == 0) {
        mins2 = '00';
      }
    }
    // var time: any = date.getHours() + ":" + date.getMinutes();
    this.timeValue = hours2 + ':' + mins2 + ' ' + ampm;
    this.pickupTimeSlots = [];
    this.server
      .getPickupslots(
        this.globals.bussinessId,
        this.date,
        this.globals.OrderType
      )
      .subscribe(
        (res) => {
          console.log(res);
          if (res.status) {
            this.showtime = true;
            this.pickupTimeSlots = res.slots;
            setTimeout(() => {
              // let id = document.getElementById('time');
              // id.scrollIntoView({ behavior: 'smooth', block: 'start' });

              if (this.comingBack == true) {
                console.log('coming back is true', this.globals.myDate);
                // this.globals.myDate = new Date(
                //   date.getTime() - date.getTimezoneOffset() * 60000
                // ).toISOString();
                // let dateval = new Date(this.globals.myDate).getTimezoneOffset() *60000;
                // console.log(dateval);
                // let date1 = new Date(this.globals.myDate).getTimezoneOffset() * 60000;
                // date1.toLocaleString()
                // this.date = new Date();
                // console.log('checking for time.........s', date1.toLocaleString());
                //new added to avoid past orders...........
                // this.globals.myDate = new Date(
                //   date.getTime() - date.getTimezoneOffset() * 60000
                // ).toISOString();

                console.log(this.globals.myDate);

                // let hour=this.globals.myDate.getHours();
                let hours = new Date(this.globals.myDate).getUTCHours();

                let minutes = new Date(this.globals.myDate).getUTCMinutes();
                // let hours =  date.getHours();
                // let minutes =  date.getMinutes();
                // let time=date.getTime()
                let ampm = hours >= 12 ? 'pm' : 'am';
                console.log(hours, '  ', minutes, 'mins hour');
                let hours2;
                if (hours < 10) {
                  console.log('hours<10');

                  hours2 = '0' + hours;
                } else {
                  console.log('hours>10');

                  hours2 = hours;
                  hours2 = hours2 % 12 || 12;
                  console.log(hours2, hours2 % 12 || 12);
                  if (hours2 < 10) {
                    console.log('hours<10 2nd time check');

                    hours2 = '0' + hours2;
                  }
                }
                let mins2;
                if (minutes < 10) {
                  console.log('mins<10');
                  mins2 = '0' + minutes;
                  if (minutes === 0 || !minutes) {
                    mins2 = '00';
                  }
                } else {
                  console.log('mins>10');

                  mins2 = minutes;
                }
                // var time: any = date.getHours() + ":" + date.getMinutes();
                this.timeValue = hours2 + ':' + mins2 + ' ' + ampm;
                console.log(this.timeValue, 'time value');
              }
            }, 1000);
          } else {
            this.globals.presentToast(res.message);
          }
          // console.log(res);
        },
        (err) => {
          console.log(err);
          this.globals.presentToast('Something went wrong,try again later');
        }
      );
  }
  setTime(time) {
    console.log(time, 'hre........');
    if (this.date == undefined || this.date == '') {
      this.globals.presentToast('Please select date first');
      return;
    }
    if (this.globals.catering_enabled) {
      if (!this.globals.cateringNumberOfPersons) {
        this.globals.presentToast('Please select number of persons first');

        return;
      }
    }
    this.globals.presentToast('Please wait..', 'bottom');

    // let type = time.slice(5,8);
    //  let hours=time.slice(0,2);
    //  let minutes=time.slice(3,5)
    //   console.log(hours,minutes,type.toLowerCase())
    //   var timeString = hours + ':' + minutes + ':' +type;
    // new Date((date.getTime()) - date.getTimezoneOffset() * 60000).toISOString();
    // console.log('time', time.time.slice(0,5));
    // let timeSelected=time.time.slice(0,5);
    // let ampm=time.time.slice(6,8)
    console.log(this.date);

    // var dateObj = new Date(this.date);

    var dateObj = new Date(this.date + ' ' + time.time);

    console.log(this.date, 'date  .....', time.time, 'time');
    console.log(dateObj, 'date object.......');

    // console.log(dateObj.getDay,dateObj.getMonth,dateObj.getFullYear);
    // let dateObj2=dateObj

    // console.log(dateObj2,'new date',dateObj2+' '+timeSelected+' '+ampm);
    // var dateObj = new Date(dateObj2+' '+timeSelected+' '+ampm);

    //will use later

    let newdate = new Date(
      dateObj.getTime() - dateObj.getTimezoneOffset() * 60000
    );
    console.log(newdate, 'new date');
    //will use later

    // this.date = new Date(new Date().setHours(new Date().getHours()));
    // var timeis = new Date(this.date).toLocaleString('en-US', { hour12: true });
    // this.showDate=newdate
    // console.log(newdate, 'newdate');
    console.log(newdate.toISOString(), 'sda');
    this.globals.myDate = newdate.toISOString();
    // let date=new Date(temp).getTime().toLocaleString()
    // console.log(dateObj);
    // sdfsd
    this.showtime = false;
    // this.process();
    this.closeWithData();
  }
  closeWithData() {
    console.log('closing');
    if (this.order_time == 'now') {
      localStorage.removeItem('scheduled_time');
      this.modalController.dismiss({
        dismissed: true,
        pickup: undefined,
      });
      this.globals.order_time = this.order_time;
    } else {
      console.log('in slse block');
      this.globals.order_time = this.order_time;
      this.globals.scheduled_pickup_time = this.globals.myDate;
      // this.globals.myDate=this.pickup_time;
      localStorage.setItem('scheduled_time', this.globals.myDate);
      if (this.globals.OrderType == 'pickup') {
        this.checkTimingLater(this.globals.pickup_timing)
          .then((resp) => {
            console.log(resp, '..............res');
            if (resp) {
              this.modalController.dismiss({
                dismissed: true,
                // pickup:this.pickup_time,
                pickup: this.globals.myDate,
              });
              // new for catering
              if (this.globals.catering_enabled) {
                // this.router.navigate(['tabs/tabs/menu']);
                if (this.globals.inWeb) {
                  this.router.navigate(['/order']);
                } else {
                  this.router.navigate(['/tabs/tabs/menu']);
                }
              }
            } else {
              let orderFrom = sessionStorage.getItem('orderFrom');
              if (orderFrom == 'school') {
                this.modalController.dismiss({
                  dismissed: true,
                  // pickup:this.pickup_time,
                  pickup: this.globals.myDate,
                });
                // new for catering
                if (this.globals.catering_enabled) {
                  // this.router.navigate(['tabs/tabs/menu']);
                  if (this.globals.inWeb) {
                    this.router.navigate(['/order']);
                  } else {
                    this.router.navigate(['/tabs/tabs/menu']);
                  }
                }
              } else {
                this.globals.presentToast(
                  'Sorry, we are not serving at this time. Please reschedule it.'
                );
              }
            }
            // this.dismiss()
          })
          .catch((error) => {});
      }
      if (this.globals.OrderType == 'delivery') {
        this.checkTimingLater(this.globals.delivery_timing)
          .then((resp) => {
            console.log(resp);
            if (resp) {
              this.modalController.dismiss({
                dismissed: true,
                // pickup:this.pickup_time,
                pickup: this.globals.myDate,
              });
              // new for catering
              if (this.globals.catering_enabled) {
                // this.router.navigate(['tabs/tabs/menu'])
                if (this.globals.inWeb) {
                  this.router.navigate(['/order']);
                } else {
                  this.router.navigate(['/tabs/tabs/menu']);
                }
              }
            } else {
              let orderFrom = sessionStorage.getItem('orderFrom');
              if (orderFrom == 'school') {
                this.modalController.dismiss({
                  dismissed: true,
                  // pickup:this.pickup_time,
                  pickup: this.globals.myDate,
                });
                // new for catering
                if (this.globals.catering_enabled) {
                  // this.router.navigate(['tabs/tabs/menu'])
                  if (this.globals.inWeb) {
                    this.router.navigate(['/order']);
                  } else {
                    this.router.navigate(['/tabs/tabs/menu']);
                  }
                }
              } else {
                this.globals.presentToast(
                  'Sorry, we are not serving at this time. Please reschedule it.'
                );
              }
            }
            // this.dismiss()
          })
          .catch((error) => {});
      }

      // localStorage.setItem('scheduled_time',this.pickup_time)
      // localStorage.setItem("scheduled_time", this.globals.myDate);
    }
  }

  checkTimingLater(timing) {
    return new Promise((resolve, reject) => {
      if (this.globals.order_time == 'schedule') {
        console.log('checking.........');
        if (this.globals.myDate) {
          console.log('date find...');
          //reeplaced with globals.mytime
          var response: any;

          if (this.globals.specific_delivery_day == 'true') {
            console.log('api call true');
            response = this.server.date_convert(this.globals.myDate);
          } else {
            response = this.server.date_convert(this.globals.myDate);
          }
          // let loading = this.loadingCtrl.create({
          //   content: "Loading...",
          // });
          // loading.present();
          response.subscribe(
            (data) => {
              console.log('api res.......');
              // loading.dismiss();
              if (data.success == true) {
                this.globals.menuTime = data.date_time;
                this.day = data.day_id + 1;
                if (this.day == 7) {
                  this.day = 0;
                }
                this.time = data.time;

                this.globals.schedule_day_id = data.day_id;
                this.globals.schedule_converted_time = data.time;

                if (this.globals.specific_delivery_day == 'true') {
                  localStorage.setItem('scheduled_time', this.globals.myDate);
                } else {
                  localStorage.setItem('scheduled_time', this.globals.myDate);
                }
                let current_day = timing[this.day];
                var n = current_day[0].indexOf('.');
                if (n != -1) {
                  var res = current_day[0].split('.');
                  current_day[0] = res[0] + '.' + '3';
                }
                var n1 = current_day[1].indexOf('.');
                if (n1 != -1) {
                  var res = current_day[1].split('.');
                  current_day[1] = res[0] + '.' + '3';
                }
                // this.time = this.time.toString();
                console.log(this.day, this.time, current_day);
                if (current_day[0] != 'opened') {
                  if (
                    (Number(current_day[0]) <= Number(this.time) &&
                      Number(current_day[1]) > Number(this.time)) ||
                    (Number(current_day[0]) <= Number(this.time) &&
                      Number(current_day[1]) < Number(current_day[0]))
                  ) {
                    resolve(true);
                  } else if (
                    current_day[0] == 'opened' &&
                    current_day[1] == 'opened'
                  ) {
                    resolve(true);
                  } else {
                    this.globals.presentToast(
                      'Sorry, we are not serving at this time. Please reschedule it.'
                    );
                    reject(true);
                  }
                } else {
                  resolve(true);
                }
              } else {
                console.log('invalid date....');
              }
            },
            (error) => {
              this.globals.presentToast(
                'Something went wrong check your internet connection.'
              );
            }
          );
        } else {
          this.globals.presentToast('please select the time');
        }
      } else {
        console.log('in pickup part 001');
        var date = new Date();
        var day: any = date.getDay();
        var time: any = date.getHours() + '.' + date.getMinutes();
        time = Number(time);

        var current_day = timing[day];

        var n = current_day[0].indexOf('.');

        console.log('in pickup part 002', date, day, time, current_day, n);
        if (n != -1) {
          var res = current_day[0].split('.');
          current_day[0] = res[0] + '.' + '3';
        }
        var n1 = current_day[1].indexOf('.');
        if (n1 != -1) {
          var res = current_day[1].split('.');
          current_day[1] = res[0] + '.' + '3';
        }
        console.log('in pickup part 003', n, n1, current_day, time);
        if (
          (Number(current_day[0]) <= time && Number(current_day[1]) > time) ||
          (Number(current_day[0]) <= time &&
            Number(current_day[1]) < Number(current_day[0]))
        ) {
          resolve(true);
        } else if (current_day[0] == 'opened' && current_day[1] == 'opened') {
          resolve(true);
        } else {
          this.globals.presentToast(
            'Sorry, we are not serving at this time. Please reschedule it.'
          );
          reject(true);
        }
      }
    });
  }
}
